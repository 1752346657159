import Modal from "react-modal";
import { useState, useEffect } from "react";
import { useDomainAnalysis } from "hooks/useDomainAnalysis"
import close_icon from "../../../../assests/icons/close_icon.png";
import Loader from "page/DashBoard/Loader"
const DomainDetails = ({ domain, setSpecificDomain }: any) => {
    const { getDomainDetails } = useDomainAnalysis()
    const [isLoading, setIsLoading] = useState(false)
    const [results, setResults] = useState<any>(null)
    useEffect(() => {
        getDomainData()
    }, [])
    const getDomainData = async () => {
        try {
            setIsLoading(true)
            const data = await getDomainDetails(domain)
            setResults(data)
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
        }
    }
    const handleClose = () => {
        setResults("")
        setSpecificDomain("")
    }
    const renderDetails = (key: any, value: any) => {
        if (Array.isArray(value)) {
            return (
                <ul>
                    {value.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            );
        } else if (typeof value === 'object' && value !== null) {
            // If the value is an object, render its keys and values
            return (
                <div>
                    {Object.entries(value).map(([nestedKey, nestedValue]) => (
                        <div key={nestedKey}>
                            <strong>{nestedKey}:</strong> {String(nestedValue)}
                        </div>
                    ))}
                </div>
            );
        } else {
            return <span>{String(value)}</span>;
        }
    };

    return (
        <Modal isOpen={true}>
            <div className="mx-auto rounded-md">
                <div
                    onClick={handleClose}
                    className="-mt-[0.8rem] float-right w-4 h-4 cursor-pointer"
                >
                    <img src={close_icon} alt="close" />
                </div>
            </div>
            <div className="flex items-center justify-between mb-5">
                <h1 className="text-3xl font-bold my-4 text-[#033d44] justify-center items-center flex">Domain Details</h1>
            </div>
            <div>
                {isLoading && <Loader />}
                {results && (
                    <div className="mt-3 rounded-lg p-3 border border-green w-full bg-[#F9FAFB] h-[400px] overflow-y-auto">
                        {Object.entries(results).map(([key, value]) => (
                            <div key={key} className="mb-2">
                                <strong>{key}:</strong> {renderDetails(key, value)}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Modal>
    )
}
export default DomainDetails
