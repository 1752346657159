import { Routes, Route, Navigate } from "react-router-dom";
import { PATHS } from "constants/index";
import Dashboard from "page/DashBoard";
import DomainAnalysis from "page/DomainAnalysis"
import BlockchainCertification from "page/BlockchainCertification"
import AddTradeMark from "page/AddTradeMark";
import Schedule from "page/Schedule";
import TrawlSection from "page/TrawlSection";
import GapFill from "page/GapFill";
import SpecificationManagement from "page/SpecificationManagement";
import BimiAnalysis from "page/Bimi/Children/BimiAnalysis";
import LicensingDashboard from "page/Licensing/Children/Dashboard";
import ReminderSystem from "page/Licensing/Children/ReminderSystem";
import SmartContract from "page/Licensing/Children/SmartContract";
import TrademarkLicense from "page/Licensing/Children/TrademarkLicense";
import CurrencyAnalysis from "page/ValuationCalculator/Children/CurrencyAnalysis";
import EnhancedValuation from "page/ValuationCalculator/Children/EnhancedValuation";
import ComprehensiveValuation from "page/ValuationCalculator/Children/ComprehensiveValuation";
import Product from "page/DetailedValuation/Children/Product";
import EBIDTAImpactAnalysis from "page/DetailedValuation/Children/EBIDTAImpactAnalysis";
import ConflictCheck from "page/ConflictCheck";
import VMCCampaignTool from "page/Bimi/Children/VMCCampaignTool";
import DNSTwister from "page/DNSTwister";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.CONFLICTCHECK} element={<ConflictCheck />} />
      <Route path={PATHS.HOME} element={<Dashboard />} />
      <Route path={PATHS.PUNNYCODE} element={<DomainAnalysis />} />
      <Route path={PATHS.BLOCKCHAINCERTIFICATION} element={<BlockchainCertification/>}/>
      <Route path={PATHS.VERIFICATION} element={<AddTradeMark/>} />
      <Route path={PATHS.SCHEDULE} element={<Schedule/>} />
      <Route path={PATHS.TRAWLSECTION} element={<TrawlSection/>} />
      <Route path={PATHS.GAPFILL} element={<GapFill/>} />
      <Route path={PATHS.SPECIFICATIONMANAGEMENT} element={<SpecificationManagement/>} />
      <Route path={PATHS.BIMIANALYSIS} element={<BimiAnalysis/>} />
      <Route path={PATHS.LICENSINGDASHBOARD} element={<LicensingDashboard/>} />
      <Route path={PATHS.LICENSINGREMINDERSYSTEM} element={<ReminderSystem/>} />
      <Route path={PATHS.LICENSINGSMARTCONTRACT} element={<SmartContract/>} />
      <Route path={PATHS.LICENSINGTRADEMARKLICENSE} element={<TrademarkLicense/>} />
      <Route path={PATHS.CALCULATORANALYSIS} element={<CurrencyAnalysis/>} />
      <Route path={PATHS.CALCULATORENHANCEDVALUATION} element={<EnhancedValuation/>} />
      <Route path={PATHS.CALCULATORCOMPREHENSIVEVALUATION} element={<ComprehensiveValuation/>} />
      <Route path={PATHS.DETAILEDVALUATIONPRODUCT} element={<Product/>} /> 
      <Route path={PATHS.DETAILEDEBIDTAIMPACTANALYSIS} element={<EBIDTAImpactAnalysis/>} /> 
      <Route path={PATHS.VMCCAMPAIGNTOOL} element={<VMCCampaignTool/>} /> 
      <Route path={PATHS.DNSTWISTER} element={<DNSTwister />} />
      <Route path="*" element={<Navigate to={PATHS.HOME} />} />
    </Routes>
  );
};
