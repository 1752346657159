import { useState } from "react"
import { useDomainAnalysis } from "hooks/useDomainAnalysis"
import Loader from "page/DashBoard/Loader"
import DomainDetails from "../DomainDetails"
const Main = () => {
    const { checkDomainDNSTwister } = useDomainAnalysis()
    const [isLoading, setIsLoading] = useState(false)
    const [DNSTwisterResults, setDNSTwisterResults] = useState([])
    const [specificDomain, setSpecificDomain] = useState("")
    const [domain, setDomain] = useState("")
    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setIsLoading(true)
            const { data }: any = await checkDomainDNSTwister(domain)
            setDNSTwisterResults(data)
            setIsLoading(false)
        }
        catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }
    return (
        <div className="container mx-auto mt-10">
            <h3 className="text-2xl text-black font-bold mb-5">DNS Twister</h3>
            <form onSubmit={handleFormSubmit}>
                <input type="text"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    className="w-full border-4 border-[#033d44] p-2.5 text-black bg-[#fbfbfb] rounded-2xl mb-3 focus:outline-none"
                    placeholder="Enter a domain, we will find similar ones..." required
                    pattern="(www\.)?[a-zA-Z0-9\-]+(\.[a-zA-Z]{2,})+"
                    title="Please enter a valid domain. Example: www.example.com or example.com"
                />
                <button
                    disabled={isLoading}
                    className="mt-3 text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 disabled:pointer-events-none"
                >
                    Check Domain
                </button>
            </form>
            {isLoading && <Loader />}
            {DNSTwisterResults.length > 0 && (
                <table className="w-full text-sm text-left border-main bg-green mt-10 pb-5">
                    <thead className="text-xs text-white uppercase bg-green border border-main sticky">
                        <tr>
                            <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">Domain</th>
                            <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">IP Address / A record</th>
                            <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider">MX record?</th>
                            <th className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {DNSTwisterResults.map((result: any, index: number) => (
                            <tr className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main" key={index}>
                                <td className="px-7 py-4 border border-main">{result.domain}</td>
                                <td className="px-7 py-4 border border-main">{result.dns_a ? result.dns_a : '-'}</td>
                                <td className="px-7 py-4 border border-main">{result.dns_mx ? "✔" : "✖"}</td>
                                <td className="px-7 py-4 border border-main"><img 
                                onClick={()=>setSpecificDomain(result.domain)}
                                className="cursor-pointer" src="https://dnstwister.report/static/media/ico/analyse.svg" /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {specificDomain && <DomainDetails domain={specificDomain} setSpecificDomain={setSpecificDomain}/>}
        </div>
    )

}
export default Main